<template>
    <vx-card :title="`Handover Purchase Order Return`">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>SR Data</span>
                    </div>
                    <div class="w-full px-4 py-2 border-black rounded vx-col sm:w-5/6 bg-grey-light">
                        <div>SR No : {{  this.form.sr_code }}</div>
                        <div>SR Type : {{ Number(this.type) > 0 ? "Without Reference" : "With Reference" }}</div>
                        <div v-if="form.po_code">PO No : {{ form.po_code }}</div>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Supplier Data</span>
                    </div>
                    <div class="w-full px-4 py-2 border-black rounded vx-col sm:w-5/6 bg-grey-light">
                        <div>Supplier No : {{ form.supplier_code }}</div>
                        <div>Name : {{ form.supplier_name }}</div>
                        <div>Address : {{ form.supplier_address }}</div>
                    </div>
                </div>
                <div class="mb-6 vx-row" v-if="form.source">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Source</span>
                    </div>
                    <div class="w-full px-4 py-2 border-black rounded vx-col sm:w-5/6 bg-grey-light">
                        <div>{{ form.source }}</div>
                    </div>
                </div>
                <br>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <label>Attachment<small style="color: red">(only: jpg, jpeg, png, heic, mp4)</small></label>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <input 
                            id="fileInput" 
                            name="file" 
                            class="w-full inputx" 
                            type="file" 
                            ref="file" 
                            multiple="multiple"
                            accept=".jpg, .jpeg, .png, .pdf, .heic, .mp4"
                            :disabled="this.docSource == 'import - PO Return Begbal' ? true : false"
                            @change="onChangeAttachment"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span></span>
                    </div>
                </div>
                <vs-divider style="margin-left: 0%">
                    List Attachment
                </vs-divider>
                <div class="mb-6 vx-row pt-2" style="margin-left: 0%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile" v-for="(tr, i) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                <td>
                                    <img :src="urlFile(tr)" style="width: 100px; height: 100px; object-fit: cover;" class="max-w-sm" @click="openPreview(i)" alt="">
                                </td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Download" v-if="tr.PathFile != ''">
                                        <vs-button 
                                            type="line" 
                                            icon-pack="feather" 
                                            icon="icon-download" 
                                            :disabled="!tr.PathFile"
                                            @click.stop="download(tr)"
                                        />
                                    </vx-tooltip>
                                </td>
                                <td class="td vs-table--td">
                                    <template>
                                        <vx-tooltip text="Delete">
                                            <vs-button 
                                                type="line" 
                                                icon-pack="feather" 
                                                icon="icon-trash"
                                                @click.stop="handleDeleteAttachment(i)"
                                            />
                                        </vx-tooltip>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
                <vue-easy-lightbox
                    :visible="visible"
                    :imgs="images"
                    :index="index"
                    @hide="handleHide"
                />
            </div>
            <div class="w-full md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Handover Code</span>
                    </div>
                    <div class="w-full px-4 py-2vx-col sm:w-5/6">
                        <vs-input :value="form.code ? form.code : 'Auto generate'" class="w-full bg-grey-light" disabled/>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Handover Date</span>
                    </div>
                    <div class="w-full px-4 py-2 vx-col sm:w-5/6 ">
                        <datepicker :disabled-dates="disabledDates" name="date" v-model="form.date" class="w-full" placeholder="Select Date" :cleared="() => { }" :disabled="this.docSource == 'import - PO Return Begbal' ? true : false"></datepicker>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Status Delivery</span>
                    </div>
                    <div class="w-full border-black rounded vx-col sm:w-5/6">
                        <multiselect
                            v-model="form.status_delivery"
                            :options="status"
                            placeholder="Type to search"
                            track-by="id"
                            label="text"
                            :max-height="125"
                            :disabled="this.docSource == 'import - PO Return Begbal' ? true : false"
                            @select="changeStatus"
                        ></multiselect>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
                <thead class="vs-table--thead">
                    <tr>
                        <th width="25%">SKU Description</th>
                        <th width="10%">HU</th>
                        <th width="10%">Return Qty</th>
                        <th width="7%">Qty</th>
                        <th width="12%">Batch</th>
                        <th>ED</th>
                        <th>Reason</th>
                        <th>Note</th>
                        <th width="5%">Handed Over</th>
                    </tr>
                </thead>
                <tbody v-if="renderTable">
                    <tr v-for="(item, index) in form.handover_lines" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-textarea
                                :value="item.item_unit ? item.item_unit.sku_code + ' / ' + item.item_unit.item_name : ''"
                                disabled
                                readonly
                                class="w-full bg-grey-light"
                                style="word-wrap: break-word;"
                            ></vs-textarea>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input
                                :value="item.item_unit ? item.item_unit.name : ''"
                                disabled
                                readonly
                                class="w-full bg-grey-light"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input type="number" min="0" disabled  :value="item.return_qty" class="w-full bg-grey-light"></vs-input>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input type="number" min="0"  v-model="item.qty" class="w-full" :disabled="[1, 3].includes(form.status_delivery.id) || item.disable_line"></vs-input>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-textarea
                                :value="`${item.batch ? item.batch : '-'}`"
                                disabled
                                readonly
                                class="w-full bg-grey-light"
                                style="word-wrap: break-word;"
                            ></vs-textarea>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input
                                :value="`${item.exp_date ? formatDate(item.exp_date) : '-'} `"
                                disabled
                                readonly
                                class="w-full bg-grey-light"
                            ></vs-input>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <multiselect
                                :disabled="form.status_delivery.id == 0 || form.status_delivery.id == 1"
                                v-model="item.reason"
                                :options="reasons"
                                placeholder="Type to search"
                                track-by="id"
                                label="reason"
                                :max-height="125"
                                @select="logSelect"
                                :select-label="''"
                                deselect-label=""
                            ></multiselect>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 5px">
                            <vs-input v-model="item.note" class="w-full"></vs-input>
                        </td>
                        <td class="td vs-table--td align-top" style="padding: 10px; align-items: center;">
                            <vs-checkbox class="mx-auto" v-model="item.handed_over" disabled></vs-checkbox>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full vx-col sm:w-1/1">
                <div
                    style="position: absolute; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); ">
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(false)">Save</vs-button>
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(true)" :disabled="form.status_delivery.id==0">Save and Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "./components/CustomMultiSelect.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        Date,
        CustomMSelect,
        vSelect,
        Datepicker,
        VueEasyLightbox
    },
    mounted() {
        this.id = Number(this.$route.params.id);
        this.form.id = this.id;
        this.return_id = isNaN(this.$route.query.r) ? 0: Number(this.$route.query.r);
        this.form.return_id = this.return_id
        this.type = isNaN(this.$route.query.t) ? 0: Number(this.$route.query.t);
        this.form.return_type = this.type
        this.initData();
    },
    data() {
        return {
            renderTable: true,
            status: [
                {
                    id: 0,
                    text: "Open",
                },
                {
                    id: 1,
                    text: "Complete",
                },
                {
                    id: 2,
                    text: "Partial Complete",
                },
                /* {
                    id: 3,
                    text: "Failed Cancel",
                }, */
            ],
            reasons: [],
            id: null,
            return_id: null,
            type: null,
            popShow: false,
            docSource: "",
            disabledDates: {
                to: moment().subtract(1, "days").toDate()
            },
            form: {
                code: "",
                id: 0,
                note: null,
                date: moment().toDate(),
                return_type: 0,
                return_id: 0,
                handover_lines: [],
            },

            // attachments section
            file: null,
            fileUrl: '',
            attachment: "",
            fileAttachment: [],
            IDAttachment: [],
            visible: false,
            images: [],
            index: 0,
        }
    },
    methods: {
        changeStatus(val) {
            let reasonGroup = "";
            if (val.id == 0 || val.id == 1) {
                // open or complete
                this.form.handover_lines.forEach((line) => {
                    line.qty = line.return_qty;
                    line.reason = null;
                    line.note = "";
                });
            }

            if (val.id == 2) {
                // partial complete
                reasonGroup = "PRO Partial Complete";
            }

            if (val.id == 3) {
                // failed cancel
                reasonGroup = "PRO Failed Cancel";
                this.form.handover_lines.forEach((line) => {
                    line.qty = 0;
                });
            }

            this.getReason(reasonGroup);
            this.logSelect(val);
        },
        getReason(reason_group) {
            if (reason_group) {
                this.$http.get(`/api/wms/v1/master/reason`, {
                    params: { reason_group },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.reasons = resp.data.records;
                        this.form.handover_lines.forEach((line, idx) => {
                            this.form.handover_lines[idx].reason = this.reasons.find((r) => r.id == line.reason_id);
                        });
                    } else {
                        //
                    }
                })
                .catch(error => {
                    console.log(error);
                })
            } else {
                this.reasons = [];
            }
        },
        logSelect(val) {
            this.renderTable = false;
            this.$nextTick(() => (this.renderTable = true));
        },
        handleSubmit(confirm = false) {
            var payload = Object.assign({}, this.form);
            payload.status_delivery = payload.status_delivery.id
            try {
                var err = [];
                payload.handover_lines.forEach((line, i) => {
                    if (payload.handover_lines[i].qty === 0) {
                        err.push("quantity cannot be a zero value");
                    }
                    payload.handover_lines[i].qty=Number(line.qty)
                    payload.handover_lines[i].reason_id=line.reason ? line.reason.id : 0
                });
                if (err.length > 0) {
                    throw new Error(err.join())
                }

                const formData = new FormData();
                formData.append("payload", JSON.stringify(payload))
                if (this.fileAttachment.length > 0) {
                    for (let index = 0; index < this.fileAttachment.length; index++) {
                        formData.append("attachments[]", this.fileAttachment[index].File);
                    }
                }

                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to submit this form?",
                    accept: () => {
                        this.$vs.loading();
                        // this.$http.post(`${this.$store.state.outbound.supplierReturn.baseUrlPath}/handover/${this.id}?confirm=${confirm}`, payload)
                        this.$http.post(`${this.$store.state.outbound.supplierReturn.baseUrlPath}/handover/${this.id}?confirm=${confirm}`, formData)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.loading.close();
                                this.$vs.notify({
                                    color: "success",
                                    title: "Updated Successfully",
                                    text: "Updated data is successfully sent to server",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                                this.handleBack();
                            } else {
                                this.$vs.loading.close();
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error)
                            this.$vs.loading.close();
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: error,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        });
                    }
                })
            } catch (error) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            }
            this.$vs.loading.close();
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: `${this.$store.state.outbound.supplierReturn.baseRouterName}.index`,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$vs.loading();
            this.$http.get(`${this.$store.state.outbound.supplierReturn.baseUrlPath}/handover/${this.id}`, {
                params: {
                    rid: this.return_id,
                    type: this.type,
                }
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.$vs.loading.close();
                    this.form = resp.data;
                    if (!this.form.date){
                        this.form.date = moment().toDate()
                    }
                    this.id = Number(this.form.id);
                    this.type = Number(this.form.return_type);
                    this.return_id = Number(this.form.return_id);
                    if (resp.data.source) {
                        this.docSource = resp.data.source
                    }
                    this.form.handover_lines.forEach((v, i) => {
                        this.form.handover_lines[i].handed_over = true
                    });
                    if (resp.data.attachments.length) {
                        resp.data.attachments.forEach((val) => {
                            this.fileAttachment.push({
                                id: val.id,
                                NameFile: val.file_name,
                                PathFile: val.file_url,
                            });
                            this.images.push(val.file_url);
                        });
                    }
                    this.form.status_delivery = this.status.find((s) => s.id == this.form.status_delivery)
                    this.changeStatus(this.form.status_delivery)
                } else {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        type: "confirm",
                        color: "danger",
                        title: `Error`,
                        text: `${resp.code} : ${resp.message}. Do you want to refetch the data?`,
                        accept: this.initData,
                    });
                }
            })
            .catch(error => {
                console.log(error)
            });
        },

        // attachments section
        download(tr) {
            const link = document.createElement('a');
            link.href = this.urlFile(tr);
            link.download = tr.NameFile;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        onChangeAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    const fileIdx = this.$refs.file.files[i]
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.images.push(URL.createObjectURL(fileIdx));
                }
                document.querySelector("#fileInput").value = "";
            }
        },
        openPreview(index) {
            this.index = index;
            this.visible = true;
        },
        handleHide() {
            this.visible = false;
        },
        handleDeleteAttachment(index) {
            this.fileAttachment.splice(index, 1);
            this.images.splice(index, 1);
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
        urlFile: () => {
            return (tr) => {
                return tr.File instanceof Blob ? URL.createObjectURL(tr.File) : tr.PathFile
            }
        }
    },
}
</script>

<style>
.upload {
    display: flex;
}

.upload button.up {
    margin: 0px !important;
}

.upload button.btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
}

.upload button.btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
}
</style>